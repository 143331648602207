import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import Switch from 'react-switch';

import { Link } from 'react-router-dom';
import slugify from 'slugify';
import ArchiveElement from '../objects/ArchiveElement';
import { shuffleArray } from '../../utils/shuffleArray';

import { accessToken, siteUrl } from '../../utils/constants';
import { DataContext } from '../../context/DataContext';

const TabStyleThree = ({ column, documents, videos }) => {
  const [tabOne, setTabOne] = useState([]);
  const [tabTwo, setTabTwo] = useState([]);
  const [viewport, setViewport] = useState({
    zoom: 1.6,
  });

  const [filters, setFilters] = useState({
    selectedAuthors: [],
    selectedDocTypes: [],
    selectedCategories: [],
    selectedLocations: [],
    selectedShowcases: [],
    selectedDublinCoreTypes: [],
    selectedKeywords: [],
  });

  const [filteredStates, setFilteredStates] = useState({
    authors: [],
    docTypes: [],
    categories: [],
    locations: [],
    showcases: [],
    dublinCoreTypes: [],
    keywords: [],
  });

  const [showMap, setShowMap] = useState(
    JSON.parse(sessionStorage.getItem('showMap')) || false
  );
  const [selectedMarker, setSelectedMarker] = useState(null);
  const { setCollectionNameAndItemId } = useContext(DataContext);

  const handleClick = (item) => {
    setCollectionNameAndItemId(item.type, item.id);
  };

  const handleKeyDown = (item, event) => {
    if (!event) {
      console.error('Event is undefined');
      return;
    }

    if (event.key === 'Enter') {
      setCollectionNameAndItemId(item.type, item.id);
    }
  };

  useEffect(() => {
    sessionStorage.setItem('showMap', JSON.stringify(showMap));
  }, [showMap]);

  useEffect(() => {
    if (!showMap) {
      setSelectedMarker(null);
      sessionStorage.removeItem('selectedMarker');
    }
  }, [showMap]);

  const extractUniqueValues = (items, keyPath, isArray = false) => {
    const values = items.flatMap((item) => {
      const value = keyPath.reduce(
        (acc, key) => acc?.[key] ?? null,
        item?.attributes?.contentType
      );
      if (isArray && Array.isArray(value)) {
        return value.map((v) => v?.attributes?.name?.trim());
      }
      return value ? [value.trim()] : [];
    });

    return [...new Set(values.filter(Boolean))].sort((a, b) =>
      a
        .replace(/[.,]/g, '')
        .toLowerCase()
        .localeCompare(b.replace(/[.,]/g, '').toLowerCase(), undefined, {
          sensitivity: 'base',
        })
    );
  };

  const loadSessionData = useCallback(() => {
    const storedFilters = [
      'Authors',
      'DocTypes',
      'Categories',
      'Locations',
      'Showcases',
      'DublinCoreTypes',
      'Keywords',
    ].reduce(
      (acc, key) => ({
        ...acc,
        [`selected${key}`]:
          JSON.parse(sessionStorage.getItem(`selected${key}`)) || [],
      }),
      {}
    );
    setFilters((prev) => ({ ...prev, ...storedFilters }));
  }, []);

  const saveSessionData = useCallback(() => {
    Object.entries(filters).forEach(([key, value]) => {
      sessionStorage.setItem(key, JSON.stringify(value));
    });
  }, [filters]);

  const filterItems = useCallback(
    (items) =>
      items.filter((item) => {
        const content = item?.attributes?.contentType;
        const keywords =
          content?.keywords?.data?.map((kw) => kw?.attributes?.name?.trim()) ||
          [];

        return (
          (!filters.selectedAuthors.length ||
            filters.selectedAuthors.includes(content?.author?.trim())) &&
          (!filters.selectedDocTypes.length ||
            filters.selectedDocTypes.includes(
              content?.doc_type?.data?.attributes?.name?.trim()
            )) &&
          (!filters.selectedCategories.length ||
            filters.selectedCategories.includes(
              content?.category?.data?.attributes?.name?.trim()
            )) &&
          (!filters.selectedLocations.length ||
            filters.selectedLocations.includes(content?.location?.trim())) &&
          (!filters.selectedShowcases.length ||
            filters.selectedShowcases.includes(
              content?.showcase?.data?.attributes?.name?.trim()
            )) &&
          (!filters.selectedDublinCoreTypes.length ||
            filters.selectedDublinCoreTypes.includes(
              content?.dublin_core_type?.data?.attributes?.name?.trim()
            )) &&
          (!filters.selectedKeywords.length ||
            filters.selectedKeywords.every((kw) =>
              keywords.includes(kw.trim())
            ))
        );
      }),
    [filters]
  );

  const updateFilteredStates = useCallback((items) => {
    setFilteredStates({
      authors: extractUniqueValues(items, ['author']),
      docTypes: extractUniqueValues(items, [
        'doc_type',
        'data',
        'attributes',
        'name',
      ]),
      categories: extractUniqueValues(items, [
        'category',
        'data',
        'attributes',
        'name',
      ]),
      locations: extractUniqueValues(items, ['location']),
      showcases: extractUniqueValues(items, [
        'showcase',
        'data',
        'attributes',
        'name',
      ]),
      dublinCoreTypes: extractUniqueValues(items, [
        'dublin_core_type',
        'data',
        'attributes',
        'name',
      ]),
      keywords: extractUniqueValues(items, ['keywords', 'data'], true),
    });
  }, []);

  useEffect(() => {
    loadSessionData();
    setTabOne(shuffleArray(videos));
    setTabTwo(shuffleArray(documents));
    updateFilteredStates([...documents, ...videos]);
  }, [documents, videos, loadSessionData, updateFilteredStates]);

  useEffect(() => {
    saveSessionData();
    const filteredItems = filterItems([...documents, ...videos]);
    updateFilteredStates(filteredItems);
  }, [
    filters,
    documents,
    videos,
    saveSessionData,
    filterItems,
    updateFilteredStates,
  ]);

  useEffect(() => {
    const filteredVideos = filterItems(videos);
    const filteredDocuments = filterItems(documents);
    setTabOne(filteredVideos);
    setTabTwo(filteredDocuments);
  }, [filters, documents, videos, filterItems]);

  useEffect(() => {
    const storedMarker = sessionStorage.getItem('selectedMarker');
    if (storedMarker) {
      setSelectedMarker(JSON.parse(storedMarker));
    }
  }, []);

  const renderTabContent = (tabData, type) =>
    tabData.map((item, index) => (
      <ArchiveElement key={index} column={column} item={item} type={type} />
    ));

  const renderSelect = (label, stateKey, id) => (
    <div className="filter-item mt-xl-4">
      <label htmlFor={id} className="mr-2 section-title">
        <span className="subtitle">{label}</span>
      </label>
      <div className="select__container">
        <Select
          id={id}
          isMulti
          options={filteredStates[stateKey].map((value) => ({
            value,
            label: value,
          }))}
          value={filters[
            `selected${stateKey.charAt(0).toUpperCase()}${stateKey.slice(1)}`
          ].map((value) => ({ value, label: value }))}
          onChange={(selectedOptions) =>
            setFilters((prev) => ({
              ...prev,
              [`selected${stateKey.charAt(0).toUpperCase()}${stateKey.slice(
                1
              )}`]: selectedOptions.map((s) => s.value),
            }))
          }
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>
    </div>
  );

  const handleMarkerSelect = (marker) => {
    setSelectedMarker(marker);
    sessionStorage.setItem('selectedMarker', JSON.stringify(marker));
  };

  const labeledDocuments = documents.map((doc) => ({
    ...doc,
    type: 'documents',
  }));
  const labeledVideos = videos.map((video) => ({ ...video, type: 'video' }));

  return (
    <div>
      <div className="col-lg-12 text-center">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title mb--30 text-center">
              <h3 className="title mt--20 mb--50">Search by:</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 d-flex flex-wrap justify-content-center filter-container mb--30">
        {[
          { key: 'authors', label: 'Authors' },
          { key: 'docTypes', label: 'Document Types' },
          { key: 'categories', label: 'Categories' },
          { key: 'locations', label: 'Locations' },
          { key: 'showcases', label: 'Cases' },
          { key: 'dublinCoreTypes', label: 'Dublin Core Types' },
          { key: 'keywords', label: 'Keywords' },
        ].map(({ key, label }) => (
          <div key={key}>{renderSelect(label, key, `${key}-filter`)}</div>
        ))}
      </div>

      <div className="switch-container text-center mt-xl-5 mb-xl-5 d-flex justify-content-center align-items-center">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor="view-toggle"
          className="mr-2 mb--30"
          style={{ marginBottom: 0 }}
        >
          Map
        </label>
        <Switch
          id="view-toggle"
          checked={showMap}
          onChange={() => setShowMap((prev) => !prev)}
          checkedIcon={false}
          uncheckedIcon={false}
        />
      </div>

      {showMap ? (
        <div
          className="mt-xl-5"
          style={{ width: '100%', height: '800px', border: '0.5px solid #ccc' }}
        >
          <ReactMapGL
            latitude={viewport.latitude}
            longitude={viewport.longitude}
            zoom={viewport.zoom}
            mapboxApiAccessToken={accessToken}
            mapStyle="mapbox://styles/mapbox/outdoors-v11"
            onViewportChange={(viewportData) => setViewport(viewportData)}
            scrollZoom={false}
            touchZoom={false}
            touchRotate={false}
            keyboard={false}
            doubleClickZoom={false}
            width="100%"
            height="100%"
          >
            <div style={{ position: 'absolute', top: 10, left: 10 }}>
              <NavigationControl />
            </div>
            {filterItems([...labeledDocuments, ...labeledVideos]).map(
              (item, index) => {
                const latitude = item?.attributes?.contentType?.latitude;
                const longitude = item?.attributes?.contentType?.longitude;

                if (latitude && longitude) {
                  return (
                    <Marker
                      key={index}
                      latitude={latitude}
                      longitude={longitude}
                    >
                      <button
                        type="button"
                        onClick={() => handleMarkerSelect(item)}
                        style={{
                          background: 'none',
                          border: 'none',
                          padding: 0,
                          cursor: 'pointer',
                        }}
                        aria-label="Select marker"
                      >
                        <img
                          src="https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png"
                          alt="marker"
                          style={{
                            width: '20px',
                            height: '20px',
                            filter:
                              selectedMarker?.id === item.id
                                ? 'hue-rotate(180deg)'
                                : 'none',
                          }}
                        />
                      </button>
                    </Marker>
                  );
                }

                return null;
              }
            )}
            {selectedMarker && (
              <div className="side-window sidebar">
                <div className="p-4">
                  <button
                    type="button"
                    onClick={() => setSelectedMarker(null)}
                    className="close-button"
                  >
                    &times;
                  </button>
                  <h4 className="mt-xl-5 mb-xl-4">
                    <Link
                      to={`archive/${
                        selectedMarker?.attributes?.contentType?.title
                          ? slugify(
                              selectedMarker.attributes.contentType.title,
                              {
                                lower: true,
                                strict: true,
                                trim: true,
                              }
                            )
                          : ''
                      }`}
                      className="no-hover"
                      onClick={() => handleClick(selectedMarker)}
                      onKeyDown={() => handleKeyDown(selectedMarker)}
                    >
                      {selectedMarker.attributes.contentType.title || ''}
                    </Link>
                  </h4>
                  {selectedMarker.attributes.contentType.image?.data?.attributes
                    ?.url && (
                    <div>
                      <Link
                        to={`archive/${
                          selectedMarker?.attributes?.contentType?.title
                            ? slugify(
                                selectedMarker.attributes.contentType.title,
                                {
                                  lower: true,
                                  strict: true,
                                  trim: true,
                                }
                              )
                            : ''
                        }`}
                        onClick={() => handleClick(selectedMarker)}
                        onKeyDown={(event) =>
                          handleKeyDown(selectedMarker, event)
                        }
                      >
                        <img
                          src={`${siteUrl}${selectedMarker.attributes.contentType.image.data.attributes.url}`}
                          alt="Archive item"
                          className="w-100"
                        />
                      </Link>
                    </div>
                  )}
                  <div className="mt-xl-4">
                    <p>{selectedMarker.attributes.contentType.description}</p>
                    <p>
                      <strong>Category:</strong>{' '}
                      {
                        selectedMarker.attributes.contentType.category?.data
                          ?.attributes?.name
                      }
                    </p>
                    <p>
                      <strong>Author:</strong>{' '}
                      {selectedMarker.attributes.contentType.author}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </ReactMapGL>
        </div>
      ) : (
        <Tabs>
          <div className="row text-center d-none">
            <div className="col-lg-12">
              <div className="tablist-inner">
                <TabList className="pv-tab-button text-center mt--0">
                  <Tab>
                    <span>All</span>
                  </Tab>
                </TabList>
              </div>
            </div>
          </div>
          <TabPanel className="row row--35">
            {tabOne.length > 0 && renderTabContent(tabOne, 'video')}
            {tabTwo.length > 0 && renderTabContent(tabTwo, 'document')}
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
};

TabStyleThree.propTypes = {
  column: PropTypes.string,
  documents: PropTypes.array.isRequired,
  videos: PropTypes.array.isRequired,
};

export default TabStyleThree;
