import React from 'react';

import PropTypes from 'prop-types';

import rehypeRaw from 'rehype-raw';
import DOMpurify from 'dompurify';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { siteUrl } from '../../utils/constants';

import TextUrl from './TextUrl';
// import TextItalics from './TextItalics';
import TextFootNote from './TextFootNote';
import TextQuote from './TextQuote';

const TextBlock = ({
  caseTitle,
  cssClass = '',
  elementId = '',
  preamble,
  textBlock,
  section = '',
}) => (
  <div id={elementId} className={`container ${cssClass}`}>
    <div className="row">
      <div className="col-lg-12">
        <div className="inner-wrapper">
          <div className="inner">
            {caseTitle && caseTitle !== '' ? (
              <h3 className="section-title text-center">{caseTitle}</h3>
            ) : (
              ''
            )}

            {textBlock && textBlock !== ''
              ? textBlock.split('\n').map((item, index) => {
                  const updatedItem =
                    item !== undefined && item.includes('http')
                      ? item
                          .replace('http://www.', 'https://www.strapi.')
                          .replace(':1338', '')
                      : item;

                  if (index === 0 && updatedItem.indexOf('**') !== -1) {
                    return (
                      <h4
                        key={`textBlockHeading${index}`}
                        className={`section-title text-center ${
                          index === 0 ? 'mt--40' : ''
                        }`}
                      >
                        {updatedItem.replace(/[**]/g, '')}
                      </h4>
                    );
                  }

                  if (
                    updatedItem === '' &&
                    cssClass.indexOf('authorCv') !== -1
                  ) {
                    return <br key={`pBreak${index}`} />;
                  }

                  let singleImgSize = '';

                  if (updatedItem.indexOf('standardSmall') !== -1) {
                    singleImgSize = 'small-centered-img';
                  }

                  if (updatedItem.indexOf('standardMedium') !== -1) {
                    singleImgSize = 'medium-centered-img';
                  }

                  if (
                    updatedItem.indexOf('![') !== -1 &&
                    section.toLowerCase() !== 'methodology'
                  ) {
                    return (
                      <div key={`textBlockImg${index}`} className="thumbnail">
                        <img
                          src={`${siteUrl}${updatedItem
                            .split('](')[1]
                            .replace(')', '')}`}
                          className={`${singleImgSize} mb-5`}
                          alt="user Uploads"
                        />
                      </div>
                    );
                  }

                  if (
                    updatedItem.indexOf('![') !== -1 &&
                    section.toLowerCase() === 'methodology'
                  ) {
                    return (
                      <div key={`textBlockImg${index}`} className="thumbnail">
                        <img
                          src={`${updatedItem
                            .split('](')[1]
                            .replace(')', '')
                            .replace('/uploads/', '/uploads/medium_')}`}
                          className={`${singleImgSize} medium-centered-img mb-5`}
                          alt="user Uploadss333"
                        />
                      </div>
                    );
                  }

                  if (updatedItem.indexOf('quote=') !== -1) {
                    return (
                      <TextQuote
                        key={`textQuoteElement${index}`}
                        quote={updatedItem.replace('quote=', '')}
                        updatedItem={updatedItem}
                      />
                    );
                  }

                  if (updatedItem.indexOf('link=') !== -1) {
                    return (
                      <p key={`TextUrl${index}`}>
                        <TextUrl tbLinks={updatedItem.split('link=')} />
                      </p>
                    );
                  }

                  if (updatedItem.indexOf('footnote=') !== -1) {
                    return (
                      <p key={`textFootNote${index}`}>
                        <TextFootNote
                          tbFootNote={updatedItem.split('footnote=')}
                        />
                      </p>
                    );
                  }

                  return (
                    <ReactMarkdown
                      key={`mark-down-${index}`}
                      rehypePlugins={[rehypeRaw]}
                      remarkPlugins={[remarkGfm]}
                      linkTarget="_blank"
                    >
                      {DOMpurify.sanitize(updatedItem)}
                    </ReactMarkdown>
                  );

                  // return <p key={`paragraph${index}`}>{updatedItem}</p>;
                })
              : ''}
            {preamble !== undefined && preamble !== null && preamble !== '' ? (
              <blockquote className="rn-blog-quote">
                <p>{preamble}</p>
              </blockquote>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

TextBlock.propTypes = {
  caseTitle: PropTypes.string,
  cssClass: PropTypes.string,
  elementId: PropTypes.string,
  preamble: PropTypes.string,
  textBlock: PropTypes.string,
  section: PropTypes.string,
};

export default TextBlock;
