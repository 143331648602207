import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import ReactMapGL from 'react-map-gl';

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import AudioPlayer from 'react-h5-audio-player';
import slugify from 'slugify';
import GifPlayer from 'react-gif-player';
import Header from './common/Header';

import Breadcrumb from './common/Breadcrumb';
import Footer from './common/FooterTwo';
import PageHelmet from './common/Helmet';

import Image from './objects/Image';
import ImagesCarouselTwo from './objects/ImagesCarouselTwo';
import VideoStandard from './objects/VideoStandard';

import { accessToken, siteUrl } from '../utils/constants';
import { getFileExtension } from '../utils/getFileExtension';
import { getArchiveDetailFields } from '../utils/getArchiveDetailFields';

import 'react-h5-audio-player/lib/styles.css';
import DataItem from './metaData/DataItem';

import { imgSize } from '../utils/funcs';

const renderDataItem = (heading, text, colSize = 4, index) => (
  <div key={heading + index} className={`col-lg-${colSize}`}>
    <div className="port-view mt--30">
      <DataItem heading={heading} text={String(text)} />
    </div>
  </div>
);

const ArchiveDetails = ({
  archiveCollectionName,
  archiveCollectionItemId,
  documents,
  setArchiveCollectionName,
  setRedirectToArchive,
  urlSlug,
  videos,
  videosVimeo,
}) => {
  const section = 'Archive';
  const [datasheet, setDatasheet] = useState({});
  const [viewport, setViewport] = useState({});
  const [videosVimeoObject, setVideosVimeoObject] = useState({});

  const handleMergedCollection = useCallback(() => {
    let mergedCollection = [];

    if (
      Array.isArray(documents) &&
      documents.length > 0 &&
      Array.isArray(videos) &&
      videos.length > 0
    ) {
      if (!archiveCollectionName || mergedCollection.length === 0) {
        mergedCollection = [...documents, ...videos];
      }

      if (archiveCollectionName === 'video') {
        mergedCollection = videos;
      }

      if (archiveCollectionName === 'documents') {
        mergedCollection = documents;
      }
    }

    return mergedCollection;
  }, [archiveCollectionName, documents, videos]);

  const findCollectionItem = useCallback(
    (mergedCollection) => {
      let collectionItem;
      if (archiveCollectionItemId) {
        collectionItem = mergedCollection.find(
          (item) => item.id === archiveCollectionItemId
        );
      } else {
        collectionItem = mergedCollection.find(
          (item) =>
            slugify(item.attributes.contentType.title, {
              lower: true,
              strict: true,
              trim: true,
            }) === urlSlug
        );

        if (!collectionItem) {
          setRedirectToArchive(true);
        } else {
          const collectionType = collectionItem.attributes.fileType
            ? 'document'
            : 'video';
          setArchiveCollectionName(collectionType);
        }
      }
      return collectionItem;
    },
    [
      archiveCollectionItemId,
      urlSlug,
      setRedirectToArchive,
      setArchiveCollectionName,
    ]
  );

  const handleArchiveDetails = useCallback(() => {
    const mergedCollection = handleMergedCollection();
    if (documents.length > 0 && mergedCollection.length > 0) {
      const collectionItem = findCollectionItem(mergedCollection);
      if (collectionItem) {
        setDatasheet({
          id: collectionItem.id,
          ...collectionItem.attributes.contentType,
          ...collectionItem.attributes,
        });

        const viewportObj = {
          latitude:
            collectionItem?.attributes?.contentType?.latitude ?? -3.9242781,
          longitude:
            collectionItem?.attributes?.contentType?.longitude ?? -75.0656716,
          zoom:
            collectionItem?.attributes?.contentType?.latitude &&
            collectionItem?.attributes?.contentType?.longitude
              ? collectionItem?.attributes?.contentType?.zoom ?? 3
              : 3,
        };

        setViewport(viewportObj);
      }
    }
  }, [documents, handleMergedCollection, findCollectionItem]);

  const handleVideosVimeo = useCallback(() => {
    if (videosVimeo.length >= 1) {
      const imgIndex = isMobileOnly ? 4 : 5;

      setVideosVimeoObject(
        videosVimeo.reduce((acc, video) => {
          acc[video.uri.replace('/videos/', '')] =
            video.pictures.sizes[imgIndex]?.link;
          return acc;
        }, {})
      );
    }
  }, [videosVimeo]);

  useEffect(() => {
    handleArchiveDetails();
    handleVideosVimeo();

    const breadcrumbElement = document.querySelector('.breadcrumb-area');
    if (breadcrumbElement) {
      const position =
        breadcrumbElement.getBoundingClientRect().bottom + window.scrollY - 65;
      window.scrollTo({ top: position, behavior: 'smooth' });
    }
  }, [handleArchiveDetails, handleVideosVimeo]);

  let counter = 0;

  const isOddAndInRange = (value, min, max) =>
    value % 2 !== 0 && value >= min && value <= max;

  return (
    <>
      <PageHelmet pageTitle="Portfolio Details" />

      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      <Breadcrumb backgroundSection={section} title={section} />

      <div className="rn-portfolio-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="section-title mb-xl-5 mb--30 text-center">
                  <span className="subtitle mb-xl-4">
                    {datasheet.showcase?.data?.attributes.name ?? ''}
                  </span>
                  <h2 className="section-title text-center">
                    {datasheet.title ?? ''}
                  </h2>
                </div>

                {/* Gif component has to be outside otherwise it cannot be centered */}
                {datasheet?.file?.data?.[0]?.attributes.ext === '.gif' && (
                  <div className="image-gif">
                    <GifPlayer
                      gif={siteUrl + datasheet?.file?.data[0]?.attributes.url}
                      autoplay
                      className="image-gif-centered"
                    />
                  </div>
                )}

                <div className="row row--35 mb--50">
                  <div className="portfolio-thumb-inner mt-xl-5 mb-xl-5 w-100">
                    <div className="thumb mb--30">
                      {datasheet?.file &&
                      datasheet.file !== '' &&
                      datasheet.file[0] !== null &&
                      datasheet?.file?.data?.[0]?.attributes?.ext !== '.pdf' &&
                      datasheet?.file?.data?.[0]?.attributes?.ext !== '.gif' &&
                      !datasheet.url &&
                      datasheet.url !== '' &&
                      archiveCollectionName !== 'video' ? (
                        <ImagesCarouselTwo images={datasheet.file.data} />
                      ) : (
                        ''
                      )}

                      {datasheet?.img &&
                      datasheet.img !== '' &&
                      datasheet.file &&
                      datasheet.file[0] &&
                      datasheet?.file?.[0]?.ext !== '.jpg' &&
                      archiveCollectionName !== 'video' ? (
                        <Image
                          imgType="standardSmall"
                          fileUrl={datasheet.url ?? ''}
                          imgUrl={imgSize(datasheet, isMobileOnly)}
                        />
                      ) : (
                        ''
                      )}

                      {datasheet?.url &&
                        datasheet.url !== '' &&
                        archiveCollectionName === 'video' && (
                          <VideoStandard
                            url={datasheet.url}
                            vidImg={
                              videosVimeoObject[
                                datasheet.url.replace('https://vimeo.com/', '')
                              ]
                            }
                          />
                        )}

                      {Object.values(datasheet).length !== 0 &&
                        datasheet.url &&
                        datasheet.url !== '' &&
                        getFileExtension(datasheet.url) !== 'mp3' &&
                        archiveCollectionName !== 'video' && (
                          <div className="text-center border border-dark">
                            <iframe
                              src={datasheet.url}
                              title="File Viewer"
                              width="100%"
                              height="1100px"
                              style={{ border: 'none' }}
                            />
                          </div>
                        )}

                      {Object.values(datasheet).length !== 0 &&
                        datasheet?.url &&
                        datasheet.url !== '' &&
                        getFileExtension(datasheet.url) === 'mp3' &&
                        archiveCollectionName !== 'video' && (
                          <div className="text-center">
                            <AudioPlayer src={datasheet.url} />
                          </div>
                        )}
                    </div>
                  </div>

                  {getArchiveDetailFields(datasheet).map((item, index) => {
                    if (index > 7 && item.text) {
                      counter += 1;
                      return (
                        <React.Fragment key={item.heading + index}>
                          {isOddAndInRange(counter, 1, 29) && (
                            <div className="col-lg-4">
                              <div className="port-view mt--20" />
                            </div>
                          )}

                          {item?.text && item?.heading === 'Citation' ? (
                            <>
                              <div className="col-lg-4">
                                <div className="port-view mt--20" />
                              </div>
                              <div className="col-lg-4">
                                <div className="port-view mt--20" />
                              </div>
                              {renderDataItem(
                                item.heading,
                                item.text,
                                item.colSize ?? '4',
                                index
                              )}
                            </>
                          ) : (
                            renderDataItem(
                              item.heading,
                              item.text,
                              item.colSize ?? '4',
                              index
                            )
                          )}
                        </React.Fragment>
                      );
                    }

                    if (item?.text) {
                      return renderDataItem(
                        item.heading,
                        item.text,
                        item.colSize ?? '4',
                        index
                      );
                    }

                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: '550px', width: '100%' }}>
        {Object.values(viewport).length !== 0 &&
        viewport?.longitude &&
        viewport?.latitude ? (
          <ReactMapGL
            latitude={viewport.latitude ?? -3.9242781}
            longitude={viewport.longitude ?? -75.0656716}
            zoom={viewport.zoom ?? 10}
            mapboxApiAccessToken={accessToken}
            mapStyle="mapbox://styles/mapbox/outdoors-v11"
            onViewportChange={(viewportData) => setViewport(viewportData)}
            dragPan={false}
            dragRotate={false}
            scrollZoom={false}
            touchZoom={false}
            touchRotate={false}
            keyboard={false}
            doubleClickZoom={false}
            width="100%"
            height="100%"
          />
        ) : (
          ''
        )}
      </div>

      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>

      <Footer />
    </>
  );
};

ArchiveDetails.propTypes = {
  archiveCollectionName: PropTypes.string,
  archiveCollectionItemId: PropTypes.number,
  documents: PropTypes.array.isRequired,
  setArchiveCollectionName: PropTypes.func,
  setRedirectToArchive: PropTypes.func,
  urlSlug: PropTypes.string.isRequired,
  videos: PropTypes.array.isRequired,
  videosVimeo: PropTypes.array.isRequired,
};

export default memo(ArchiveDetails);
